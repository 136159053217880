@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$linkSize: 32px;

.container {
  padding: 6px;
}

.title {
  color: $dark-text;
  @include fontSmall;
  margin: 0 0 6px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: $height - (18px + 12px);

  svg {
    fill: $dark-text;
  }

  img,
  svg {
    height: $linkSize;
    width: $linkSize;
    border-radius: 50%;
  }

  a {
    display: flex;

    &:hover {
      img,
      svg {
        transform: scale(1.05);
      }
    }
  }
}
