@import '../styles/variables.scss';

.container {
  position: absolute;
  top: 0;
  transform: translateY(100%);
  height: 100%;
  width: 100%;
  z-index: 10;
  transition-property: transform;
  transition-duration: 0.25s;
  transition-timing-function: $easeInCirc;

  &Active {
    transform: translateY(0);
  }
}

.closeBtn {
  position: absolute;
  right: 2px;
  top: 2px;
  border-width: 0;
  background-color: transparent;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  svg {
    height: 20px;
    width: 20px;
    fill: $dark-text;
  }
}
