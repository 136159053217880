@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.container {
  position: relative;
  height: $height;

  &Double {
    height: $height * 2;
  }
}

.content {
  position: relative;
  overflow: hidden;

  &Shadow::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    box-shadow: inset 4px 4px 5px rgba(0, 0, 0, 0.5),
      inset -4px -4px 5px rgba(0, 0, 0, 0.5);
  }
}

.backdrop {
  z-index: -1;
  display: block;
  background-color: $dark-backdrop;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &Cover {
    filter: blur(4px);
    background-size: cover;
    background-position: center;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.5;
    }
    &Dark::after {
      background-color: $dark-coverBackdrop;
    }
    &Light::after {
      background-color: $light-coverBackdrop;
    }
  }
}

.loader {
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  visibility: visible;
  transition-property: opacity, visibility;
  transition-duration: 0.25s;
  transition-timing-function: $easeInCirc;
  // transition-delay: 0.125s;
  z-index: 10;

  &Ready {
    opacity: 0;
    visibility: hidden;
  }
}

.error {
  color: $dark-backgroundBar;
  @include fontMedium;
  padding: 10px;
  margin: 0;
  text-align: center;
}
