@import '../styles/variables.scss';

$barHeight: 4px;
$seekerSize: 12px;

.container {
  position: relative;
  height: $seekerSize;
  width: 100%;

  &Active {
    .seeker {
      opacity: 1;
    }
    .filled {
      background-color: $primary;
    }
  }
}

.background,
.filled {
  height: $barHeight;
  border-radius: $barHeight/2;
  position: absolute;
  width: 100%;
}

.background {
  background-color: $dark-backgroundBar;
  overflow: hidden;
  top: 50%;
  transform: translateY(-50%);
}

.filled {
  background-color: $dark-filledBar;
}

.seekerTrack {
  position: absolute;
  height: $seekerSize;
  width: 100%;
}

.seeker {
  background-color: $dark-seeker;
  height: $seekerSize;
  width: $seekerSize;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
