$primary: #6236ff;

// Dark theme
$dark-backdrop: #465065;
$dark-coverBackdrop: #000000;
$dark-text: #d8d8d8;
$dark-backgroundBar: #a8aaad;
$dark-filledBar: #d8d8d8;
$dark-seeker: #d8d8d8;

// Light theme
$light-backdrop: #ffffff;
$light-coverBackdrop: #ffffff;
$light-text: #495057;
$light-backgroundBar: #a8aaad;
$light-filledBar: #d8d8d8;

$height: 140px;

$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
