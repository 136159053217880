@import '../../styles/variables.scss';

$labelLeftPadding: 2px;

.container {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.action {
  padding: 0 10px 0 0;
  margin: 0;
  background-color: transparent;
  border-width: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  svg {
    height: 18px;
    width: 18px;
    fill: $dark-text;
  }

  span {
    opacity: 0;
    transition-property: opacity, margin-left;
    transition-duration: 0.25s;
    transition-timing-function: $easeInCirc;
    padding-left: $labelLeftPadding;
    color: $dark-text;
  }

  &.description {
    span {
      margin-left: -(57px + $labelLeftPadding);
    }
  }
  &.sharing {
    span {
      margin-left: -(38px + $labelLeftPadding);
    }
  }
  &.subscribe {
    span {
      margin-left: -(50px + $labelLeftPadding);
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    span {
      opacity: 1;
      margin-left: 0;
    }
  }
}
