@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.container {
  padding: 4px 6px 6px;
}

.title {
  margin: 0 0 4px;
  @include fontMedium;
  color: $dark-text;
}

.description {
  margin: 0;
  @include fontMedium;
  color: $dark-text;
}

.scroll {
  height: $height - (15px + 6px + 4px + 4px);
}
