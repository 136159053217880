@import '../../styles/variables.scss';

.player {
  display: flex;
}

.main {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  height: $height;
}
.controls {
  position: relative;
  height: $height;
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.25s;
  transition-timing-function: $easeInCirc;

  &.drawerActive {
    opacity: 0;
  }
}

.image {
  height: $height;
}

.title {
  margin: 0;
  font-size: 10px;
  color: $dark-text;
}

.episodeTitle {
  margin: 0;
  font-size: 12px;
  color: $dark-text;
}

.head {
  left: 6px;
  right: 6px;
  padding: 0 6px;
  position: absolute;
  top: 6px;
}

.playBtn {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.soundControl {
  position: absolute;
  right: 6px;
  padding-right: 6px;
  top: 60%;
  transform: translateY(-50%);
}

.footer {
  left: 6px;
  right: 6px;
  position: absolute;
  bottom: 6px;
}

.actions {
  margin-top: 2px;
}
