@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.container {
  height: $height - 12px;
  position: relative;
  padding: 6px;

  &::before {
    content: '';
    height: 10px;
    display: block;
    position: absolute;
    z-index: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.25) 100%
    );
    top: 0;
    left: 0;
    right: 0;
  }
}

.title {
  color: $dark-text;
  @include fontSmall;
  margin: 0 0 6px;
}

.list {
  margin: 0;
  padding: 0 4px 0 0;
  list-style: none;
  position: relative;
  z-index: 1;

  &Container {
    height: $height - 18px - 12px;
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: $dark-backgroundBar;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $dark-filledBar;
      border-radius: 2px;
    }
  }

  &Item {
    cursor: pointer;
    color: $dark-text;
    @include fontMedium;
    display: flex;
    align-items: center;
    border-top: 1px solid $dark-text;

    svg {
      fill: $dark-text;
      width: 24px;
      height: 24px;
    }

    &Active,
    &:hover {
      color: white;
      svg {
        fill: white;
      }
    }

    &:first-child {
      border-top-width: 0;
    }
  }
}
