@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
}

.time {
  display: flex;
  justify-content: space-between;
  p {
    margin: 0 8px;
    font-size: 10px;
  }
}

.progress {
  padding: 0 6px;
}

.containerDark {
  .time p {
    color: $dark-text;
  }
}

.containerLight {
  .time p {
    color: $light-text;
  }
}
