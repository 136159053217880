@import '../../styles/variables.scss';

.container {
  display: flex;
  width: 60px;
  align-items: center;
}

.volumeIcon {
  width: 12px;
  height: 12px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    fill: $dark-backgroundBar;
    width: 12px;
    height: 12px;
    &:hover {
      fill: $dark-filledBar;
    }
  }
}

.progress {
  flex-grow: 1;
}
