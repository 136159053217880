@import '../styles/variables.scss';

.button {
  margin: 0;
  padding: 0;
  border-width: 0;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 2px solid $dark-backgroundBar;

  &:focus {
    outline: none;
  }

  &:hover {
    transform: scale(1.05);
    border-color: $dark-filledBar;
    svg {
      fill: $dark-filledBar;
    }
  }

  svg {
    fill: $dark-backgroundBar;
    height: 24px;
    width: 24px;
  }
}
