@import '../styles/variables.scss';

.container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  svg {
    width: 50px;

    path {
      fill: $dark-text;
      transform: scale(1, 1);
      transform-origin: bottom;
      animation: equalizer 2s infinite;

      // FIRST BAR
      &:nth-child(6) {
        animation-delay: 0;
      }
      // SECOND BAR
      &:nth-child(5) {
        animation-delay: -1s;
      }
      // THIRD BAR
      &:nth-child(4) {
        animation-delay: -0.5s;
      }
      // FOURTH BAR
      &:nth-child(3) {
        animation-delay: -1.25s;
      }
      // FIFTH BAR
      &:nth-child(2) {
        animation-delay: -0.75s;
      }
      // SIXTH BAR
      &:nth-child(1) {
        animation-delay: -0.25s;
      }
    }
  }
}

$rangeMax: 1;
$rangeMin: 0.6;

@function rangedScale($scale) {
  @return scale(1, $rangeMin + ($rangeMax - $rangeMin) * $scale);
}

@keyframes equalizer {
  0% {
    transform: rangedScale(0.1);
  }
  10% {
    transform: rangedScale(0.3);
  }
  20% {
    transform: rangedScale(0.5);
  }
  30% {
    transform: rangedScale(0.2);
  }
  40% {
    transform: rangedScale(0.7);
  }
  50% {
    transform: rangedScale(0.9);
  }
  60% {
    transform: rangedScale(0.3);
  }
  70% {
    transform: rangedScale(0.8);
  }
  80% {
    transform: rangedScale(0.5);
  }
  90% {
    transform: rangedScale(0.3);
  }
  100% {
    transform: rangedScale(0.1);
  }
}
