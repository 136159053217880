@import '../styles/variables.scss';

.container {
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: $dark-backgroundBar;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $dark-filledBar;
    border-radius: 2px;
  }
}
